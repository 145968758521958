
/*
 * 		Footer
 * 		------
 */

.footer {
	clear: both;
	//background: $primaryDark url('../images/background_footer.jpg') no-repeat center center;
	//background-size: cover;
	background-color: $primaryDark;
	padding: 0px;

	.reading-text {

		@media screen and (max-width: 767px) {
			text-align: center;
			font-size: 14px;
			line-height: 24px;
		}
	}

	.footer-wrapper {
		display: flex;
		flex-direction: row;
		width: 100%;
		padding: 80px 50px;

		@media screen and (max-width: 767px) {
			padding: 15px 25px;
			flex-direction: column;
		}

		.footer-block {
			width: calc(100% / 3);

			@media screen and (max-width: 991px) {
				width: 50%;
			}

			@media screen and (max-width: 767px) {
				width: 100%;
				margin: 25px 0;
			}

			.title-underlined {
				color: #fff;

				@media screen and (max-width: 767px) {
					padding-bottom: 15px;
					margin-bottom: 20px;
					font-size: 20px;
					text-align: center;

					&:after {
						left: 50%;
						margin-left: -25px;
					}
				}
			}

			ul {
				@media screen and (max-width: 767px) {
					margin-top: 30px;
				}

				.list-item {
					padding: 0 0 20px 0;
					text-transform: uppercase;
					font-size: 16px;

					a {
						transition: all .3s ease-in-out;

						&:hover {
							color: $primaryBlue;
						}
					}

					&.spaced {
						padding-left: 20px;
					}

					&:last-child {
						padding-bottom: 0;
					}

					@media screen and (max-width: 767px) {
						text-align: center;
					}
				}
			}

			.input-group-button {

				form {
					@media screen and (max-width: 767px) {
						display: flex;
						justify-content: center;
					}
				}

				.input {
					width: 215px;
					max-width: 100%;
					padding: 12px 10px 13px;
					font-family: $secondaryFont;
					font-size: 16px;
				}

				.submit {
					padding: 15px 20px 10px;
					transition: all 0.3s ease-in-out;

					&:hover {
						background-color: $primaryRed;
					}
				}
			}
		}
	}

	.copyright-footer {
		display: block;
		width: 100%;
		padding: 20px 50px;
		border-top: 1px solid $thirdDark;
		overflow: auto;
		color: $primaryLight;
		font-size: 14px;

		@media screen and (max-width: 767px) {
			padding: 20px 25px;
			text-align: center;
		}

		.left-block {
			float: left;

			@media screen and (max-width: 767px) {
				width: 100%;
				display: block;
				margin: 0;
			}

			.reading-text {
				margin: 0;
				color: $whiteFontColor;
			}
		}

		.right-block {
			float: right;

			@media screen and (max-width: 767px) {
				width: 100%;
				display: block;
				margin: 10px 0 0;
			}

			.link {
				color: $whiteFontColor;
				padding: 0 5px;

				@media screen and (max-width: 767px) {
					line-height: 18px;
				}

				&:hover {
					color: $primaryBlue;
				}
			}
		}

		.reading-text {
			font-size: 14px;
		}
	}
}



