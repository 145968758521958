
/*
 * 		Colors
 * 		------
 */

$bodyColor			: #E4E9EC;

$primaryLight	 	: #ffffff;
$secondaryLight		: #f3f3f3;
$thirdLight			: #cccccc;
$fourthLight		: #f7f7f7;
$fifthLight			: #ced2d5;

$primaryBlue 		: #2A9FD8;
$primaryRed 		: #d22642;


$primaryDark		: #272D33;
$secondaryDark		: #494E54;
$thirdDark			: #52565c;
$fourthdDark		: #728184;




/*
 * 		Fonts
 * 		-----
 */


$primaryFont 	 	: 'proxima-nova';
$secondaryFont 	 	: 'brandon-grotesque';

$primaryFontColor	: #282d34;
$secondaryFontColor	: #cccccc;
$thirdFontColor		: #878888;
$fourthFontColor	: #707377;
$fifthFontColor		: #282d34;
$whiteFontColor		: #ffffff;
$menuColor        : #bdbebf;




/*
 * 		Social
 * 		-----
 */


$twitterColor		: #009CDE;
$facebookColor		: #3b5998;
$instagramColor		: #285b85;
$googleColor		: #7d7d7d;
$linkedinColor		: #2c86ba;
$pinterestColor		: #c21f25;







/*
 * 		Breakpoints
 * 		-----------
 */


$tinyBreakpoint 	: 519px; // SMALL SMART-PHONE
$smallBreakpoint 	: 767px; // BIG SMART-PHONE
$mediumBreakpoint 	: 1070px; // IPAD
$largeBreakpoint 	: 1309px; // WEB

