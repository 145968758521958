.home-content {
  background-color: $primaryLight;

  section {
    display: block;
    width: 100%;
    max-width: 100vw;
    padding: 80px 50px;
    border-bottom: 2px solid $secondaryLight;

    @media (max-width: 767px) {
      padding: 40px 25px;
    }

    .section-title {
      font-family: $primaryFont;
      font-size: 24px;
      line-height: 30px;
      font-weight: 400;
      color: $primaryFontColor;
      padding: 0;
      margin: 0;
      margin-bottom: 30px;

      .v-line {
        position: relative;
        margin-left: 0px;
        padding-left: 15px;

        &:before {
          content: '';
          width: 2px;
          height: 100%;
          background: #2A9FD8;
          position: absolute;
          top: 0;
          left: 50%;
          margin-left: -1px;
        }
      }

      .subtitle {
        font-weight: 300;
      }

      @media (max-width: 767px) {
        text-align: center;
        font-size: 20px;
        line-height: 26px;

        .v-line {
          display: none;
        }

        .subtitle {
          display: block;
          font-size: 16px;
        }
      }
    }

    .reading-text {
      margin: 0;
      line-height: 24px;

      @media (max-width: 767px) {
        text-align: center;
      }
    }

    .button {
      margin: 0;

      @media (max-width: 767px) {
        width: 250px;
        max-width: 100vw;
      }
    }
  }

  .main-carousel {

    .carousel-cell {
      width: 100%;
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }

    .flickity-page-dots {

      bottom: 20px;

      .dot {
        width: 12px;
        height: 12px;
        background-color: transparent;
        border: 2px solid $primaryBlue;
        opacity: 1;

      }

      .dot.is-selected {
        background-color: $primaryBlue;
      }
    }

    .flickity-prev-next-button {

      background-color: transparent;

      .arrow {
        fill: $primaryLight;
      }

      &:disabled {
        opacity: 1;

      }
    }

    @media (max-width: 599px) {
      .flickity-viewport {
        margin-bottom: 25px;
      }

      .flickity-page-dots {
        bottom: -45px;

        .dot {
          -webkit-transform: scale(.85);
              -ms-transform: scale(.85);
                  transform: scale(.85);
        }
      }

      .flickity-prev-next-button svg {
        -webkit-transform: scale(.85);
            -ms-transform: scale(.85);
                transform: scale(.85);
      }
    }
  }

  .home-grid {
    .grid {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -js-display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      margin-bottom: 50px;

      @media (max-width: 767px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
      }
    }

    .half {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -js-display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;

      @media (max-width: 767px) {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
      }
    }

    .half-vertical {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -js-display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }

    .content {
      padding: 5px;
    }

    img {
      display: block;
      width: 100%;
      max-width: 100%;
    }
  }

  .half-cta {
    position: relative;
    padding: 0;
    border-bottom-width: 1px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -js-display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;

    @media (max-width: 767px) {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }

    .content-left,
    .content-right {
      width: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -js-display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 80px 50px;

      @media (max-width: 767px) {
        width: 100%;
        padding: 40px 25px;
      }

      .wrap {
        // display: flex;
        -js-display: flex;
        // flex-direction: column;
        // justify-content: center;
        max-width: 100%;
        text-align: center;
      }

      .title-3 {
        text-transform: initial;
        margin-bottom: 20px;

        @media (max-width: 767px) {
          font-size: 20px;
        }
      }

      .reading-text {
        width: 500px;
        max-width: 100%;
        margin-bottom: 20px;
      }
    }

    .content-right {
      background-color: $primaryDark;

      .title-3, .reading-text, .button {
        color: $primaryLight;
      }
    }
  }
}