
/*
 * 		Tilte 1 with underline
 * 		---------
 */


.title-underlined {
	position: relative;
	font-family: $secondaryFont;
	font-size: 24px;
	line-height: 28px;
	letter-spacing: 0.05em;
	font-weight: 300;
	padding-bottom: 25px;
	margin-bottom: 25px;
	text-transform: uppercase;
	color: $primaryLight;

	&.has-border {
		&:after {
			content: "";
			position: absolute;
			bottom: -2px;
			left: 0;
			width: 50px;
			height: 4px;
			background-color: $primaryRed;

			@media screen and (max-width: $smallBreakpoint) {
				height: 2px;
				bottom: -1px;
			}
		}
	}

	&.small {
		font-size: 20px;
		line-height: 20px;

		&:after {
			bottom: -10px;
		}
	}

	&.medium {
		font-size: 35px;
		line-height: 35px;

		@media screen and (max-width: $tinyBreakpoint) {
			font-size: 25px;
			line-height: 25px;
		}
	}

	&.large {
		font-size: 42px;
		line-height: 50px;

		@media screen and (max-width: $smallBreakpoint) {
			font-size: 35px;
			line-height: 35px;
		}
	}

	&.dark {
		color: $primaryFontColor;
	}

	&.centered {
		text-align: center;

		&:after {
			display: block;
			left: 50%;
			margin-left: -29px;
		}
	}
}




/*
 * 		Heading 1
 * 		---------
 */


.title-1 {

	margin: 25px 0;
	color: $primaryLight;
	text-transform: uppercase;
	font-size: 60px;
	font-weight: 300;
	line-height: 60px;

	@media screen and (max-width: $mediumBreakpoint) {

		font-size: 40px;
		line-height: 40px;
	}

	@media screen and (max-width: $tinyBreakpoint) {

		font-size: 25px;
		line-height: 25px;
	}
}




/*
 * 		Heading 2
 * 		---------
 */


.title-2 {
	color: $primaryFontColor;
	text-transform: uppercase;
	font-size: 35px;
	font-weight: 300;

	@media screen and (max-width: $smallBreakpoint) {
		font-size: 25px;
	}
}




/*
 * 		Heading 3
 * 		---------
 */


.title-3 {
	color: $fifthFontColor;
	text-transform: uppercase;
	font-size: 20px;
	font-weight: 300;
	margin: 0;

	&.light {
		color: $primaryLight;
	}
}




/*
 * 		Title with select
 * 		---------
 */


.title-with-select {
	width: 100%;
	position: relative;
	padding: 0 0 25px 0;
	height: 65px;

	@media screen and (max-width: $smallBreakpoint) {
		height: auto;
		padding: 0 0 20px 0;
	}

	&:after {
		content: '';
		position: absolute;
		z-index: 0;
		top: 17px;
		width: 100%;
		border-top: 3px solid $primaryLight;

		@media screen and (max-width: $smallBreakpoint) {
			content: none;
		}
	}

	.title-2 {
		position: absolute;
		z-index: 1;
		line-height: 40px;
		background-color: $bodyColor;
		padding-right: 40px;

		@media screen and (max-width: $largeBreakpoint) {
			font-size: 25px;
		}

		@media screen and (max-width: $mediumBreakpoint) {
			font-size: 20px;
		}

		@media screen and (max-width: $smallBreakpoint) {
			position: relative;
			height: auto;
			padding-right: 0;
			background-color: inherit;
			line-height: inherit;
		}
	}

	.select-group {
		position: absolute;
		z-index: 1;
		right: 0;
		width: auto;

		@media screen and (max-width: $smallBreakpoint) {
			display: block;
			width: 100%;
			margin-top: 10px;
			position: relative;
		}

		.select {
			padding: 10px 40px 10px 10px;
		}
	}
}




/*
 * 		Reading Text
 * 		---------
 */


.reading-text {
	font-size: 16px;
	line-height: 24px;
	margin: 0 0 25px;
	font-weight: 300;

	@media screen and (max-width: $tinyBreakpoint) {

		font-size: 14px;
		line-height: 16px;
	}

	&.light {

		color: $primaryLight;
	}

	&.centered {

		text-align: center;
	}

	p {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}
	}
}




/*
 * 		Link
 * 		---------
 */


.link {

	color: $thirdLight;
	font-family: $primaryFont;
	text-decoration: none;

	&:hover {

		color: $primaryBlue ;
		transition: 		color .2s ease;
		-moz-transition: 	color .2s ease;
		-webkit-transition: color .2s ease;
	}

	&.blue {

		color: $primaryBlue ;
	}
}




/*
 * 		Active Filter
 * 		---------
 */


.active-filter {

	display: inline-block;
	border-radius: 3px;
	margin: 0 5px;
	background-color: $primaryLight;
	font-size: 0.850em;

	.label {

		display: inline-block;
		padding: 10px 15px;
	}

	.unselect-filter {

		display: inline-block;
		padding: 10px 15px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		color: $primaryBlue;
		background-color: $primaryDark;
	}

	&:hover {

		color: $primaryBlue;
	}
}

a {

	outline: none;
}

.centered {

	text-align: center;
}



