.home-buttons.notre-entreprise {
  padding: 0;

  .image-with-product-nav {
    position: relative;
    padding: 140px 50px;
    //background-image: url('/images/riobelpro-2017/hero-notre-entreprise.jpg');

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.6);
    }

    @media screen and (max-width: 1070px) {
      padding: 100px 20px;
    }

    @media screen and (max-width: 767px) {
      padding: 50px 20px;
    }
  }

  &.garanties-certifications {
    .image-with-product-nav {
      background-image: url('/images/riobelpro-2017/hero-garantie.jpg');
      background-position: top;
    }
  }
}

.notre-entreprise-container {
  padding: 80px 50px;
  background-color: $primaryLight;

  @media (max-width: 767px) {
    padding: 40px 25px;
  }

  .section-title {
    font-family: $primaryFont;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: $primaryFontColor;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;

    @media (max-width: 767px) {
      text-align: center;
    }

    .v-line {
      position: relative;
      margin-left: 0px;
      padding-left: 15px;

      &:before {
        content: '';
        width: 2px;
        height: 100%;
        background: #2A9FD8;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -1px;
      }
    }

    .subtitle {
      font-weight: 300;
    }

    @media (max-width: 767px) {
      text-align: center;
      font-size: 20px;
      line-height: 26px;

      .v-line {
        display: none;
      }

      .subtitle {
        display: block;
        font-size: 16px;
      }
    }
  }

  .logo-list {
    display: flex;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 30px;
    }

    a {
      display: flex;
      align-items: center;
      margin: 0 50px 0 0;

      @media (max-width: 767px) {
        margin: 0 0 20px 0;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    img {
      display: flex;
      width: 150px;
      max-width: 100%;
      height: auto;
    }
  }

  .reading-text {
    line-height: 24px;
    font-family: $primaryFont;
    color: $primaryFontColor;

    @media (max-width: 767px) {
      text-align: center;
    }
  }
}
