
/*
 * 		Common Styles
 * 		-------------
 */

html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  min-width: 300px;
  overflow-x: hidden;
  overflow-y: scroll;
  text-rendering: optimizeLegibility;
}

html,
body {
	width: 100%;
	min-height: 100%;
	margin: 0;
	padding: 0;
	font-family: $primaryFont;
	background-color: $bodyColor;
	font-size: 16px;
	line-height: 1em;
}


div,
p,
header,
nav,
aside,
section,
article {

	margin: 0;
	padding: 0;
	box-sizing: border-box;
}



textarea,
input,
select{

    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;

    &:focus {

	    outline: none;
    }
}

.clearfix {

	clear: both;
}

.alert {

	padding: 20px;
	border-radius: 2px;
	color: white;

	&.success {

		background: #76CE88;
	}
}

