
/*
 * 		Home Styles
 * 		-----------
 */

#hero-banner {

	transition: height .2s ease;
	position: relative;
	height: calc(100vh - (50px + 73px));

	@media screen and (max-width: $mediumBreakpoint) {

		height: calc(100vh - 46px);
	}

	.image-block {

		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	.arrow-down {

		position: absolute;
		right: 50px;
		bottom: 50px;
		cursor: pointer;

		@media screen and (max-width: $tinyBreakpoint) {

			display: none;
		}
	}

	.text-block {

		@media screen and (max-width: $tinyBreakpoint) {

			.title-underlined {

				margin-bottom: 20px;
				font-size: 16px;
			}

			.title-1 {

				font-size: 18px;
			}

			.button.blue {

				padding: 10px;
			}
		}
	}
}

.home-buttons {

	padding: 50px 50px 0;

	@media screen and (max-width: $mediumBreakpoint) {

		padding: 20px 20px 0;
	}

	.image-with-product-nav {

		width: 100%;
		padding: 140px 50px;
		background: url('../images/home-product-background.jpg') no-repeat center center;
		background-size: cover;
		text-align: center;

		@media screen and (max-width: $mediumBreakpoint) {

			padding: 100px 20px;
		}

		@media screen and (max-width: $smallBreakpoint) {

			padding: 50px 20px;
		}
	}

	&.inspiration {

		padding: 0;

		.image-with-product-nav {

			padding: 300px 50px;

			@media screen and (max-width: $largeBreakpoint) {

				padding: 200px 50px;
			}

			@media screen and (max-width: $mediumBreakpoint) {

				padding: 100px 20px;
			}

			@media screen and (max-width: $smallBreakpoint) {

				padding: 50px 20px;
			}
		}
	}
}

.product-showcase {

	background: $primaryLight;

	.home-products {

		padding: 0 50px 50px;

		@media screen and (max-width: $mediumBreakpoint) {

			padding: 0 20px 20px;
		}

		@media screen and (max-width: $tinyBreakpoint) {

			padding: 0 0 20px;
		}

		.product-block-row {

			border-left: 1px solid $fifthLight;

			@media screen and (max-width: $tinyBreakpoint) {

				border-left: none;

				.product-block {

					width: 100%;

					&.show-all-cta {

						.product-block {

							width: 50%;
						}
					}
				}
			}
		}

		.swiper-wrapper {

			display: inherit;

			@media screen and (max-width: $largeBreakpoint) {

				.product-block {

					width: 25%;
				}

				.show-all-cta .product-block {

					width: 50%;
				}
			}

			@media screen and (max-width: $tinyBreakpoint) {

				display: -webkit-box;
				border-left: 1px solid $fifthLight;

				.swiper-slide {

					border-top: 1px solid $fifthLight;
				}

				.product-block {

					width: 100%;
				}
			}
		}
	}

}

.home-swipers {

	background: $primaryLight;
	padding: 0 0 50px;

	@media screen and (max-width: $mediumBreakpoint) {

		padding: 0 0 20px;
	}

	.swiper-container {

		padding-bottom: 50px;

		@media screen and (max-width: $mediumBreakpoint) {

			padding-bottom: 0;
		}
	}

	.swiper-pagination {

		bottom: 0;

		@media screen and (max-width: $mediumBreakpoint) {

			display: none;
		}

		.swiper-pagination-bullet {

			opacity: 1;
			background: white;
			border: 2px solid $primaryBlue;
			transition: background ease-out .2s;

			&.swiper-pagination-bullet-active {

				background: $primaryBlue;
				border: 2px solid $primaryBlue;
			}
		}
	}

	.single-swiper-block {

		width: 100%;

		.single-swiper-wrapper {

			position: relative;
			width: 100%;
			height: 0;
			padding-bottom: 45%;

			@media screen and (max-width: $mediumBreakpoint) {

				height: auto;
				padding-bottom: 0;
			}

			.swiper-image {

				position: absolute;
				width: 65%;
				top: 0;
				right: 0;
				bottom: 0;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;

				@media screen and (max-width: $tinyBreakpoint) {

					width: 100%;
					top: -150px;
					left: 0;
					right: 0;
					bottom: inherit;
					height: 151px;
				}
			}

			.swiper-content {

				position: absolute;
				width: 50%;
				top: 50%;
				transform: translateY(-50%);
				padding: 50px;
				background: $primaryFontColor;
				color: $primaryLight;

				@media screen and (max-width: $largeBreakpoint) {

					padding: 35px;
					width: 60%;
				}

				@media screen and (max-width: $mediumBreakpoint) {

					position: relative;
					transform: inherit;
				}

				@media screen and (max-width: $tinyBreakpoint) {

					width: 100%;
					margin-top: 150px;
					padding: 30px;
				}



				.reading-text {

					font-weight: 300;
				}

				.button {

					margin: 0;
				}
			}
		}
	}

	.swiper-slide:nth-child(odd) .swiper-content {

		background-image: url('../images/home-swiper-bottom.png');
		background-repeat: no-repeat;
		background-position: right bottom;
	}

	.swiper-slide:nth-child(even) .swiper-content {

		background-image: url('../images/home-swiper-top.png');
		background-repeat: no-repeat;
		background-position: right top;
	}
}