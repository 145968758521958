.home-buttons.realisations {
  padding: 0;

  .image-with-product-nav {
    position: relative;
    padding: 140px 50px;
    background-image: url('/images/riobelpro-2017/hero-realisations.jpg');

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,.6);
    }

    @media screen and (max-width: 1070px) {
      padding: 100px 20px;
    }

    @media (max-width: 767px) {
      padding: 40px 25px;
    }
  }
}

.realisations-container {
  padding: 50px 75px;
  max-width: 1050px;
  margin: 0 auto;

  @media screen and (max-width: 1070px) {
    padding: 20px;
  }

  .realisations-intro-text {
    font-family: $primaryFont;
    font-size: 17px;
    margin-bottom: 50px;
    color: $primaryFontColor;
    text-align: center;
    line-height: 24px;
    font-weight: 300;

    @media screen and (max-width: 1070px) {
      margin-bottom: 20px;
    }
  }

  .iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 35px;
    height: 0;
    overflow: hidden;

    iframe {
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}